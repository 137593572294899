<template>
  <div id="calendar">
    <!-- <v-data-table
      :headers="headers"
      :items="desserts"
      item-key="name"
      class="elevation-1 table-setting"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    > -->
    <template>
      <div class="d-flex">
        <div style="width: 50%">
          <v-text-field
            v-model="search"
            label="Search (UPPER CASE ONLY)"
            class="mx-4 search-bar"
            single-line
            hide-details
          >
          </v-text-field>
        </div>
        <!-- Branch dropdown -->
        <div>
          <v-col>
            <v-select
              :items="allBranches"
              item-value="id"
              item-text="id"
              v-model="selected_branch"
              dense
              outlined
              @change="changeBranch()"
              background-color="white"
              class="custom-select"
              style="width: 100%"
            >
            </v-select>
          </v-col>
        </div>
        <!-- End branch dropdown -->

        <div>
          <!-- Date block -->
          <v-col cols="5" style="text-align: center">
            <v-btn-toggle class="date_button">
              <v-btn color="white" @click="prev" active-class="remove-opacity">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                background-color="white"
                :class="focus == today ? 'v-btn--active' : ''"
                text
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn
                background-color="white"
                width="215px"
                text
                active-class="remove-opacity"
              >
                {{ top_filter_date }}
              </v-btn>
              <v-btn color="white" @click="next" active-class="remove-opacity">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <!-- End Date block -->
        </div>
      </div>
      <v-row>
        <v-col>
          <p class="transaction">Transaction summary</p>
          <v-data-table
            :headers="transaction_headers"
            :items="transaction"
            hide-default-footer
            class="elevation-1 Transaction"
          ></v-data-table>
        </v-col>
        <v-col>
          <p class="transaction">Cash movement summary</p>
          <v-data-table
            :headers="cash_summary_headers"
            :items="cash_summary"
            hide-default-footer
            class="elevation-1 Transaction"
          ></v-data-table>
        </v-col>
      </v-row>
    </template>
    <!-- </v-data-table> -->
  </div>
</template>

<script>
import moment from "moment";
import getTimes from "../helpers/get_times.js";
import CommonApi from "@/services/CommonApi";

export default {
  name: "Daily-Sales",
  data() {
    return {
      search: "",
      calories: "",
      allBranches: [{ id: "All Branch" }],
      selected_branch: "",
      focus: new Date().toISOString().substr(0, 10),
      today: new Date().toISOString().substr(0, 10),
      top_filter_date: moment().format("dddd, D MMMM Y"),
      ready: false,
      getTimes: getTimes(),
      transaction: [
        {
          Item: "Services",
          Sales: "0",
          Refund: "0",
          Gross: "₹0.00",
        },
        {
          Item: "Products",
          Sales: "0",
          Refund: "0",
          Gross: "₹0.00",
        },
        {
          Item: "Shipping",
          Sales: "0",
          Refund: "0",
          Gross: "₹0.00",
        },
        {
          Item: "Vouchers",
          Sales: "0",
          Refund: "0",
          Gross: "₹0.00",
        },
        {
          Item: "Memberships",
          Sales: "0",
          Refund: "0",
          Gross: "₹0.00",
        },
        {
          Item: "Late cancellation fees",
          Sales: "0",
          Refund: "0",
          Gross: "₹0.00",
        },
        {
          Item: "No show fees",
          Sales: "0",
          Refund: "0",
          Gross: "₹0.00",
        },
        {
          Item: "Total Sales",
          Sales: "0",
          Refund: "0",
          Gross: "₹0.00",
        },
      ],
      cash_summary: [
        {
          Payment: "Cash",
          collected: "₹0.00",
          Refunds: "₹0.00",
        },
        {
          Payment: "Other",
          collected: "₹0.00",
          Refunds: "₹0.00",
        },
        {
          Payment: "Voucher Redemptions ",
          collected: "₹0.00",
          Refunds: "₹0.00",
        },
        {
          Payment: "Payments collected",
          collected: "₹0.00",
          Refunds: "₹0.00",
        },
        {
          Payment: "Of which tips",
          collected: "₹0.00",
          Refunds: "₹0.00",
        },
      ],
    };
  },
  computed: {
    transaction_headers() {
      return [
        { text: "Item type", align: "left", sortable: false, value: "Item" },
        { text: "Sales qty", sortable: false, value: "Sales" },
        { text: "Refund qty", sortable: false, value: "Refund" },
        { text: "Gross total", sortable: false, value: "Gross" },
      ];
    },
    cash_summary_headers() {
      return [
        {
          text: "Payment type",
          align: "left",
          sortable: false,
          value: "Payment",
        },
        { text: "Payments collected", sortable: false, value: "collected" },
        { text: "Refunds paid", sortable: false, value: "Refunds" },
      ];
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    formatted_blocked_date() {
      return this.blocked_date
        ? moment(this.blocked_date).format("dddd, D MMMM Y")
        : "";
    },
  },
  mounted() {
    this.fetchEvents();
    this.ready = true;
    this.selected_branch = this.allBranches[0];
    this.top_filter_date = moment(this.focus).format("dddd, D MMMM Y");
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    formatDate: function (date) {
      const newDate = new Date(date.days[0].date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        newDate
      );
      let mm = new Intl.DateTimeFormat("en", { month: "long" }).format(newDate);
      let dd = new Intl.DateTimeFormat("en", {
        day: "2-digit",
      }).format(newDate);
      let tt = new Intl.DateTimeFormat("en", {
        weekday: "long",
      }).format(newDate);

      return `${tt} ${dd} ${mm}, ${ye}`;
    },
    setToday() {
      this.focus = new Date().toISOString().substr(0, 10);
      this.top_filter_date = moment(this.focus).format("dddd, D MMMM Y");
    },
    prev() {
      this.top_filter_date = moment(this.top_filter_date)
        .subtract(1, "days")
        .format("dddd, D MMMM Y");
    },
    next() {
      this.top_filter_date = moment(this.top_filter_date)
        .add(1, "days")
        .format("dddd, D MMMM Y");
    },
    changeBranch() {
      this.allCategories = [{ id: "all", employee_name: "All Employees" }];
      this.fetchEvents();
    },
    async fetchEvents() {
      CommonApi.get_data("innosoft_salon.api.get_branch").then((res) => {
        if (res.status_code == 200) {
          this.allBranches.push(...res.data);
        }
      });
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold !important;
}
.v-text-field {
  width: 85%;
}
.search-bar {
  /* margin-left: 600px !important; */
  margin-bottom: 10px;
}
.transaction {
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  text-align: center;
}
</style>